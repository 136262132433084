@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  background-color: #222;
  color: white;
  font-family: "Anonymous Pro", monospace;
  overflow: hidden;
}

button {
  margin: 5px 10px;
  color: white;
  background-color: #222;
  border: 1px solid white;
  padding: 5px 10px;
  font-family: "Anonymous Pro", monospace;
}

button:hover {
  cursor: pointer;
  background-color: rebeccapurple;
}

.main {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.mainContainer {
  display: block;
  width: 1000px;
  height: 1414px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.mainContainer2 {
  border: 1px solid white;
  padding: 10px;
  margin: 20px;
}

.padding {
  width: 100%;
}

.buttonContainer {
  z-index: 999;
  margin: 10px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonContainer input {
  height: 1.2rem;
  width: 250px;
  line-height: 2rem;
  min-height: 2rem;
  background-color: #222;
  border: 1px solid white;
  color: white;
  padding: 3px;
  margin: 5px;
}

.recording {
  -webkit-animation-name: recordingAnim;
          animation-name: recordingAnim;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transition: background-color 0.5s;
}

@-webkit-keyframes recordingAnim {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #ea5966;
  }
  100% {
    background-color: #222;
  }
}

@keyframes recordingAnim {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #ea5966;
  }
  100% {
    background-color: #222;
  }
}
/*# sourceMappingURL=Main.css.map */
body {
  background-color: #222;
  color: white;
  font-family: "Anonymous Pro", monospace;
}

button {
  margin: 5px 10px;
  color: white;
  background-color: #222;
  border: 1px solid white;
  padding: 5px 10px;
  font-family: "Anonymous Pro", monospace;
}

button:hover {
  cursor: pointer;
  background-color: rebeccapurple;
}

.main {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.mainContainer {
  display: block;
  width: 1000px;
  height: 1414px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.buttonContainer {
  z-index: 999;
  margin: 10px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonContainer input {
  height: 1.2rem;
  width: 250px;
  background-color: #222;
  border: 1px solid white;
  color: white;
  padding: 3px;
}

.recording {
  -webkit-animation-name: recordingAnim;
          animation-name: recordingAnim;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transition: background-color 0.5s;
}

@-webkit-keyframes recordingAnim {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #ea5966;
  }
  100% {
    background-color: #222;
  }
}

@keyframes recordingAnim {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #ea5966;
  }
  100% {
    background-color: #222;
  }
}

.artworkContainer {
  width: 1000px;
  height: 1414px;
  position: relative;
  margin: 10px;
  display: block;
  background-color: #1d191b;
}

.artworkCanvas {
  z-index: 10;
  width: 1000px;
  height: 1414px;
  position: absolute;
  top: 0px;
  display: block;
  background-color: #1d191b;
}

.react-p5 {
  position: absolute;
  left: 0px;
  width: 1000px;
  height: 1414px;
  z-index: 10;
}

.react-p5 .p5Canvas {
  left: 0px;
  display: hidden;
  position: absolute;
}

.focusPointCanvas {
  opacity: 0;
  left: 0px;
  position: absolute;
  width: 1000px;
  height: 1414px;
}

.maskCanvas {
  display: none;
  left: 0px;
  position: absolute;
  width: 1000px;
  height: 1414px;
  z-index: 10;
}

.canvas {
  display: none;
  left: 0px;
  position: absolute;
  width: 1000px;
  height: 1414px;
  z-index: 10;
}

.backgroundLayer {
  left: 0px;
  display: none;
  position: absolute;
  overflow: hidden;
  width: 1000px;
  height: 1414px;
}

.backgroundLayer svg {
  position: absolute;
  z-index: inherit;
  width: 100%;
  height: 100%;
}

.backgroundLayer #backgroundAccent {
  z-index: 1;
}

.backgroundLayer img {
  position: absolute;
  z-index: 2;
}

.svgLayer {
  left: 0px;
  display: none;
  position: absolute;
  width: 1000px;
  height: 1414px;
  z-index: 10;
  overflow: hidden;
}

.textboxLayer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  height: 100%;
  align-content: center;
}

.textboxLayer .textbox {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.textboxLayer .textbox .top {
  top: 2%;
  padding: 0px 3%;
}

.textboxLayer .textbox .left {
  min-width: 100%;
  padding: 0px 3%;
  max-height: 20%;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.textboxLayer .textbox .right {
  min-width: 100%;
  padding: 0px 3%;
  max-height: 20%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.textboxLayer .textbox .bottom {
  bottom: 2%;
  padding: 0px 3%;
}

.red {
  fill: #ea5966;
}

.red-dark {
  fill: #a1304a;
}

.red-dark2 {
  fill: #64233b;
}

.blue {
  fill: #74a7a1;
}

.blue-dark {
  fill: #47707a;
}

.blue-dark2 {
  fill: #354551;
}

.pink {
  fill: #fb6ba3;
}

.pink-dark {
  fill: #a14481;
}

.pink-dark2 {
  fill: #5c2e58;
}

.orange {
  fill: #f68c63;
}

.orange-dark {
  fill: #d54e28;
}

.orange-dark2 {
  fill: #6f2a18;
}

.green {
  fill: #86bb8e;
}

.green-dark {
  fill: #467564;
}

.green-dark2 {
  fill: #344a3b;
}

.purple {
  fill: #88799a;
}

.purple-dark {
  fill: #5d5b8a;
}

.purple-dark2 {
  fill: #3f3852;
}

.cream {
  fill: #efdbc0;
}

.cream-dark {
  fill: #f3c78f;
}

.cream-dark2 {
  fill: #906045;
}

.gray {
  fill: #8f7c79;
}

.gray-dark {
  fill: #574444;
}

.gray-dark2 {
  fill: #322b2d;
}

.white {
  fill: #ffffff;
}

.brown {
  fill: #c08984;
}

.black {
  fill: #1d191b;
}

mask {
  mask-type: alpha;
}
/*# sourceMappingURL=Artwork.css.map */
